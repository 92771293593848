import { Navigate } from "react-router-dom";
import About from "../components/modal&components/About";
import ClearCache from "../components/ClearCache";
import Contact from "../components/modal&components/Contact";
import Dashboard from "../pages/Dashboard";
import DigitalMedia from "../components/modal&components/newsEvents/DigitalMedia";
import Faq from "../components/modal&components/Faq";
import Fixtures from "../components/modal&components/fixtures";
import GalleryMedia from "../components/modal&components/GalleryMedia";
// import MediaCoverages from "../components/MediaCoverage";
import Gallery from "../pages/Gallery";
import Game from "../components/modal&components/engagements/games";
import Home from "../pages";
import Leaderboard from "../components/modal&components/LeaderBoard";
import Media from "../pages/NewsEvents";
import MediaCoverage from "../components/modal&components/newsEvents/PrintMedia";
import MembersLounge from "../pages/MembersLounge";
import Photobooth from "../components/modal&components/engagements/Photobooth";
import PlayerDetail from "../components/modal&components/PlayerDetail";
import Quiz from "../components/modal&components/engagements/Quiz";
import Squad from "../pages/Squad";
import SpinWheel from "../components/modal&components/engagements/SpinWheel";
import Sponsor from "../components/modal&components/Sponsor";
import Stats from "../components/modal&components/Stats";
import UserAccount from "../components/modal&components/user/UserAccount";
import UserProfile from "../components/modal&components/user/UserProfile";
import Merchandise from "../components/modal&components/Merchandise";
import Blog from "../components/modal&components/Blog";

const commonChildrenRoutes = [
  { element: <About />, path: "about" },
  { element: <Blog />, path: "blog" },
  { element: <Contact />, path: "contact" },
  { element: <DigitalMedia />, path: "digital_media" },
  { element: <Faq />, path: "faq" },
  {
    element: <Fixtures />,
    path: "fixtures",
    children: [
      { element: <Fixtures />, path: ":tab" },
      { element: <Fixtures />, path: ":tab/:currPage" },
      { element: <Fixtures />, path: ":tab/:currPage/:matchNo" },
    ],
  },
  { element: <Leaderboard />, path: "leaderboard" },
  {
    element: <MediaCoverage />,
    path: "media_coverage/:season",
  },
  {
    element: <MediaCoverage />,
    path: "media_coverage/:season/:photoIndex",
  },
  { element: <Sponsor />, path: "sponsor" },
  {
    element: <Stats />,
    path: "stats/:statsFor",
  },
  {
    element: <Merchandise />,
    path: "merchandise",
  },
];

export const commonRoutes = [
  { element: <Home />, path: "/" },
  { element: <ClearCache />, path: "/clear_cache" },
  {
    element: <Dashboard />,
    children: [
      ...commonChildrenRoutes,
      { element: <UserAccount />, path: "user_account/:account" },
      { element: <UserProfile />, path: "user_profile" },
    ],
    path: "/dashboard",
  },
  {
    element: <Gallery />,
    children: [
      ...commonChildrenRoutes,
      { element: <GalleryMedia />, path: "season/:season/:galleryFor" },
      {
        element: <GalleryMedia />,
        path: "season/:season/:galleryFor/:mediaId",
      },
      {
        element: <GalleryMedia />,
        path: "season/:season/:galleryFor/:mediaId/:photoIndex",
      },
    ],
    path: "gallery",
  },
  {
    element: <Media />,
    children: commonChildrenRoutes,
    path: "/news_events",
  },
  {
    element: <MembersLounge />,
    children: [
      ...commonChildrenRoutes,
      {
        element: <Photobooth />,
        path: "photobooth",
      },
      {
        element: <SpinWheel />,
        path: "spin_wheel",
      },
      {
        element: <Game />,
        path: "games/:game",
      },
      {
        element: <Quiz />,
        path: "quiz",
      },
    ],
    path: "/members_lounge",
  },
  {
    element: <Squad />,
    children: [
      ...commonChildrenRoutes,
      {
        element: <PlayerDetail />,

        path: "player_detail/:playerId",
      },
    ],
    path: "squad",
  },
  {
    element: <Navigate to="/" />,
    path: "*",
  },
];
