import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { ActionContext, PopupContext } from "../context.js";
import Popup from "../components/modal&components/Modal";
import { dashboardHotspots } from "../utils/fixer";
import { mainBackground } from "../assets/img/index.js";
import useWindowDimensions from "../components/dimensions.js";

const Dashboard = () => {
  const { hfAction } = useContext(ActionContext);
  const { popupProps } = useContext(PopupContext);

  const { width } = useWindowDimensions();

  const mainRef = useRef(null);

  const [oldUser, setOldUser] = useState(localStorage.getItem("oldUser")); // eslint-disable-line

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const [activeHotspot, activateHotspot] = useState(null);

  const redirect = () => {
    window.open("https://styched.life/collections/patna-pirates", "_blank");
  };

  const handleNavigation = (page = hfAction.var) => {
    navigate(page);
    hfAction.fn(null);
  };

  const handleClosePopup = () => {
    navigate("/dashboard");
    hfAction.fn(null);
    popupProps.fn({});
  };

  useEffect(() => {
    if (pathname.split("/").length - 1 === 1) {
      handleClosePopup();
      document.title = "Patna Pirates | Dashboard";
    }
  }, [pathname]); // eslint-disable-line

  useEffect(() => {
    if (hfAction.var) handleNavigation();
  }, [hfAction.var]); // eslint-disable-line

  useEffect(() => {
    localStorage.setItem("oldUser", "true");

    const interval = setInterval(() => {
      if (mainRef.current) {
        if (mainRef.current.scrollWidth > width)
          mainRef.current.scrollLeft =
            (mainRef.current.scrollWidth - width) / 2;
        clearInterval(interval);
      }
    }, 50);

    return () => clearInterval(interval);
  }, []); // eslint-disable-line

  return (
    <div className="wrapper" ref={mainRef}>
      {pathname.split("/").length - 1 > 1 &&
      !pathname.includes("user_account") ? (
        <Popup onClose={handleClosePopup}>
          <Outlet context={{ close: handleClosePopup }} />
        </Popup>
      ) : (
        <div className="imageOuter">
          <img src={mainBackground} alt="" />

          <div className="hotspots">
            {dashboardHotspots.map((item, key) => (
              <div
                className={`hotspot-item hotspot-item-${key + 1}`}
                key={key}
                name={item.name}
                onClick={() => {
                  if (item.name === "merchandise") redirect();
                  else handleNavigation(item.name);
                }}
                onMouseEnter={() => activateHotspot(key)}
                onMouseLeave={() => activateHotspot(null)}
              >
                <Link
                  name={item.name}
                  style={
                    !oldUser
                      ? {}
                      : activeHotspot === key
                      ? { minWidth: 72, border: "1px solid #fff" }
                      : { background: "transparent", minWidth: 72 }
                  }
                >
                  <span
                    className="pulsating-circle"
                    style={
                      !oldUser
                        ? {}
                        : activeHotspot !== key
                        ? { width: 14, height: 14 }
                        : {}
                    }
                    name={item.name}
                  />
                  {activeHotspot === key || !oldUser ? (
                    <>
                      {item.title}
                      <span className="arrow_box" name={item.name} />
                    </>
                  ) : null}
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
      {pathname.includes("user_account") && (
        <Popup onClose={handleClosePopup}>
          <Outlet context={{ close: handleClosePopup }} />
        </Popup>
      )}
    </div>
  );
};
export default Dashboard;
