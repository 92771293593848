import { headerMenu } from "../utils/fixer";

const Header = ({ action, textColor, user }) => (
  <header className="header">
    <div className="leftLogo" onClick={() => action("/")}>
      {/* <img src={logo} alt="" /> */}
    </div>
    <div className="userside">
      <nav style={{ display: "flex" }}>
        {headerMenu.map((item, key) => (
          <span
            key={key}
            name={item.name}
            onClick={() => {
              if (item.name === "tickets")
                window.open(
                  "https://in.bookmyshow.com/sports/pro-kabaddi-season-10-patna-pirates/ET00382522",
                  "_blank"
                );
              else action(item.name);
            }}
            style={!key ? { marginRight: 5 } : {}}
          >
            <span className={`user-icon ${item.className || ""}`}>
              <span
                className={`${item.name === "tickets" ? "ticket-icon" : ""}`}
              >
                <img
                  alt={user.name || item.name}
                  src={item.name === "tickets" ? item.icon : (user.image || item.icon)}
                  style={{
                    maxHeight: item.name === "tickets" ? 22 : 44,
                    maxWidth: item.name === "tickets" ? 22 : 44,
                    borderRadius: item.name === "tickets" ? 0 : "50%",
                    border: (item.name !== "tickets" && user.image) ? "2px solid #fff" : "none",
                  }}
                />
              </span>{" "}
              {!!user.name && (
                <span
                  style={
                    typeof textColor === "string" ? { color: textColor } : {}
                  }
                >
                  {item.name !== "tickets" && !!user.name
                    ? `Hi 
                  ${
                    user.name.includes(" ")
                      ? user.name.split(" ")[0]
                      : user.name
                  }`
                    : null}
                </span>
              )}
            </span>
          </span>
        ))}
      </nav>
    </div>
  </header>
);

export default Header;
